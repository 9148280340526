module.exports = [{
      plugin: require('/codebuild/output/src147277468/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src147277468/src/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://analytics.mycryptoapi.com","siteUrl":"https://cryptoscamdb.org"},
    },{
      plugin: require('/codebuild/output/src147277468/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
