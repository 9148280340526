// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src147277468/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-domain-tsx": () => import("/codebuild/output/src147277468/src/src/pages/domain.tsx" /* webpackChunkName: "component---src-pages-domain-tsx" */),
  "component---src-pages-address-tsx": () => import("/codebuild/output/src147277468/src/src/pages/address.tsx" /* webpackChunkName: "component---src-pages-address-tsx" */),
  "component---src-pages-404-tsx": () => import("/codebuild/output/src147277468/src/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("/codebuild/output/src147277468/src/src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("/codebuild/output/src147277468/src/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mission-tsx": () => import("/codebuild/output/src147277468/src/src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-report-tsx": () => import("/codebuild/output/src147277468/src/src/pages/report.tsx" /* webpackChunkName: "component---src-pages-report-tsx" */),
  "component---src-pages-scams-tsx": () => import("/codebuild/output/src147277468/src/src/pages/scams.tsx" /* webpackChunkName: "component---src-pages-scams-tsx" */),
  "component---src-pages-search-tsx": () => import("/codebuild/output/src147277468/src/src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-verified-tsx": () => import("/codebuild/output/src147277468/src/src/pages/verified.tsx" /* webpackChunkName: "component---src-pages-verified-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src147277468/src/.cache/data.json")

